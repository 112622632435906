import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogPost.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’m a big fan of the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API"
      }}>{`Fetch API`}</a>{`. I use it regularly in all sorts of projects, including this site and the API that powers the stats on the `}<a parentName="p" {...{
        "href": "/about/"
      }}>{`about page`}</a>{`. However it isn’t always as clear how to do things like error handling and request timeouts as it is in libraries like `}<a parentName="p" {...{
        "href": "https://github.com/axios/axios"
      }}>{`Axios`}</a>{`.`}</p>
    <p>{`If you’re not familiar with `}<inlineCode parentName="p">{`fetch`}</inlineCode>{`, it’s a native API that massively simplifies making AJAX requests compared to the older `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/XMLHTTPRequest"
      }}>{`XHR`}</a>{` method, and `}<a parentName="p" {...{
        "href": "https://caniuse.com/#feat=fetch"
      }}>{`it’s supported in all modern browsers`}</a>{`. When it initially landed, however, there was no easy way to handle request timeouts. You could fake it with `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/race"
      }}><inlineCode parentName="a">{`Promise.race`}</inlineCode></a>{` or by `}<a parentName="p" {...{
        "href": "https://github.com/github/fetch/issues/175#issuecomment-216791333"
      }}>{`wrapping your `}<inlineCode parentName="a">{`fetch`}</inlineCode>{` in another Promise`}</a>{`, but these solutions don’t actually cancel the request. This is where `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/AbortController"
      }}><inlineCode parentName="a">{`AbortController`}</inlineCode></a>{` comes in.`}</p>
    <p><inlineCode parentName="p">{`AbortController`}</inlineCode>{` is an API that, much like its name and my previous sentence suggests, allows us to abort (cancel) requests. Though `}<a parentName="p" {...{
        "href": "https://caniuse.com/#feat=abortcontroller"
      }}>{`browser support isn’t `}<em parentName="a">{`wonderful`}</em>{` at time of writing`}</a>{`, it can be used in most modern browsers and `}<a parentName="p" {...{
        "href": "https://github.com/mo/abortcontroller-polyfill"
      }}>{`polyfills are available`}</a>{`. The API itself has a very small surface area: a `}<inlineCode parentName="p">{`signal`}</inlineCode>{` property to attach to request objects, and an `}<inlineCode parentName="p">{`abort`}</inlineCode>{` method to actually cancel the request. Because the API is so simple, it’s very flexible — Jake Archibald has `}<a parentName="p" {...{
        "href": "https://developers.google.com/web/updates/2017/09/abortable-fetch"
      }}>{`a fairly in-depth article on the Google Developers blog`}</a>{` going over various cancellation scenarios, as well as the history behind the API, and I highly recommend giving it a read.`}</p>
    <p>{`With `}<inlineCode parentName="p">{`AbortController`}</inlineCode>{`, it becomes trivial to cancel a request if it doesn’t resolve before a given period of time: if the `}<inlineCode parentName="p">{`abort`}</inlineCode>{` method is called before the request resolves (or before the response `}<inlineCode parentName="p">{`Body`}</inlineCode>{` is consumed), the request is cancelled; if it’s called after, the browser just ignores the call. To put it all together, we need to:`}</p>
    <ol>
      <li parentName="ol">{`Create an instance of `}<inlineCode parentName="li">{`AbortController`}</inlineCode></li>
      <li parentName="ol">{`Create a `}<inlineCode parentName="li">{`setTimeout`}</inlineCode>{` function that calls the controller’s `}<inlineCode parentName="li">{`abort`}</inlineCode>{` method`}</li>
      <li parentName="ol">{`Pass the controller’s `}<inlineCode parentName="li">{`signal`}</inlineCode>{` to `}<inlineCode parentName="li">{`fetch`}</inlineCode>{`’s options object`}</li>
    </ol>
    <h2>{`Putting It All Together`}</h2>
    <p>{`First, because we’re basically writing a shim around `}<inlineCode parentName="p">{`fetch`}</inlineCode>{`, I’m going to add an extra little perk. If the response doesn’t return in the `}<inlineCode parentName="p">{`200`}</inlineCode>{` range (that is, if `}<inlineCode parentName="p">{`response.ok`}</inlineCode>{` evaluates to `}<inlineCode parentName="p">{`false`}</inlineCode>{`), we’re going to throw an error. We absolutely do not need to do this — we could just catch our timeout and the function would work the same (we actually don’t even `}<em parentName="p">{`need`}</em>{` to do that). However, I always perform this check anyways, so this removes a lot of boilerplate code for me.`}</p>
    <p>{`Anyways, here is my generic `}<inlineCode parentName="p">{`fetchWithTimeout`}</inlineCode>{` function. It should work in any environment that supports `}<inlineCode parentName="p">{`fetch`}</inlineCode>{` and `}<inlineCode parentName="p">{`AbortController`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const fetchWithTimeout = (uri, options = {}, time = 5000) => {
  // Lets set up our \`AbortController\`, and create a request options object
  // that includes the controller's \`signal\` to pass to \`fetch\`.
  const controller = new AbortController()
  const config = { ...options, signal: controller.signal }

  // Set a timeout limit for the request using \`setTimeout\`. If the body of this
  // timeout is reached before the request is completed, it will be cancelled.
  const timeout = setTimeout(() => {
    controller.abort()
  }, time)

  return fetch(uri, config)
    .then(response => {
      // Because _any_ response is considered a success to \`fetch\`,
      // we need to manually check that the response is in the 200 range.
      // This is typically how I handle that.
      if (!response.ok) {
        throw new Error(\`\${response.status}: \${response.statusText}\`)
      }

      return response
    })
    .catch(error => {
      // When we abort our \`fetch\`, the controller conveniently throws a named
      // error, allowing us to handle them separately from other errors.
      if (error.name === 'AbortError') {
        throw new Error('Response timed out')
      }

      throw new Error(error.message)
    })
}
`}</code></pre>
    <p>{`Using the function is fairly straightforward. Because we return `}<inlineCode parentName="p">{`fetch`}</inlineCode>{` directly, we can use it in much the same way; the only change should be the addition of a third parameter (our `}<inlineCode parentName="p">{`time`}</inlineCode>{` argument) and the extra error handling we discussed above.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// This example _always_ logs the error, because I'm telling httpstat.us to wait
// at least 1s before responding, but setting the timeout threshold to 500ms.
// Also, this could definitely be written in async/await if you preferred.
fetchWithTimeout(
  'https://httpstat.us/200?sleep=1000',
  { headers: { Accept: 'application/json' } },
  500
)
  .then(response => response.json())
  .then(json => {
    console.log(\`This will never log out: \${json}\`)
  })
  .catch(error => {
    console.error(error.message)
  })
`}</code></pre>
    <hr></hr>
    <p>{`That’s it. That’s the whole post. Though the snippet is ultimately pretty simple (it’s 20 lines without whitespace and comments) writing this provided me with three major benefits: it forced me to abstract the function to the most reusable version I could, it gave me an opportunity to research `}<inlineCode parentName="p">{`AbortController`}</inlineCode>{` to make sure I knew exactly how it behaved, and it provided a place where I can come find this snippet in the future instead of rooting through old projects.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      